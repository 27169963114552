import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent, ForgotPasswordComponent, AdminAccessComponent } from '@mt-ng2/login-module';
import { ResetPasswordComponent } from 'admin-portal/reset-password/reset-password.component';
import { SignUpSuccessComponent } from 'admin-portal/sign-up-success/sign-up-success.component';

import { appPaths } from './admin-portal/default-routes/app-paths.library';

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginComponent, title: 'Login' },
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent, title: 'Forgot Password' },
    { path: appPaths.resetPassword, component: ResetPasswordComponent, title: 'Reset Password' },
    { path: appPaths.adminAccess, component: AdminAccessComponent, title: 'Admin Access' },
    { path: appPaths.signUpSuccess, component: SignUpSuccessComponent, title: 'Verify Account' },
    { path: 'open-app', loadChildren: () => import('./install-app/install-app.module').then((m) => m.InstallAppModule), title: 'Install App' },
    { canLoad: [() => true], path: '', loadChildren: () => import('./admin-portal/admin-portal.module').then((m) => m.AdminPortalModule) },
    // add CanLoad/CanActivate guards to the child routing modules as needed
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
