<div class="row">
    <div class="col-md-4 col-sm-0"></div>
    <div class="col-md-4 col-sm-12">
        <div class="page">
            <img class="logo-img" src="/assets/logo-full.png" alt="Logo" />
            <div class="content">
                <img class="header-img" src="/assets/images/almost-there.png" alt="Almost there" />

                <div class="text">
                    <p>
                        Your security is super important to us. Check your email so we can verify your identity, then you'll be good to go!
                    </p>
                    <p><strong>Note:</strong> Be sure to check your spam folder</p>
                </div>
                <img src="/assets/images/verification.png" alt="Verification logo" class="verification" />
                <div class="text">Having trouble?</div>
                <button class="mt-button">Contact Us</button>
            </div>
        </div>
    </div>
</div>
