<div class="row">
        <div class="page">
            <div class="header text-center" style="width: auto; background-color: white;max-height:400px;">
                <img class="logo-img" [src]="logoFull" alt="Logo" />
            </div>
            <div *ngIf="!passwordResetSuccess" class="login-box">
                <div class="login-box-body">
                    <p class="login-box-msg">
                        {{ config.messageOverrides.resetPasswordLabel }}
                    </p>
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                        <div class="form-group has-feedback" [class.has-error]="passwordHasError()">
                            <label>New Password</label
                            ><i
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-html="true"
                                data-placement="right"
                                [title]="config.messageOverrides.failedPattern"
                            ></i>
                            <input
                                #Password
                                [type]="passwordType"
                                autofocus
                                autocomplete="off"
                                class="form-control input mb-1"
                                placeholder="Password"
                                formControlName="Password"
                            />
                            <div
                                *ngIf="passwordType === 'password'"
                                class="fa fa-eye-slash form-control-feedback"
                                (click)="togglePasswordType()"
                            ></div>
                            <div *ngIf="passwordType === 'text'" class="fa fa-eye form-control-feedback" (click)="togglePasswordType()"></div>
                            <div *ngIf="showPasswordRequiredError()" class="small errortext" [style.position]="'block'">Password is required</div>
                            <div *ngIf="showPasswordMustMatchError()" class="small errortext" [style.position]="'block'">Passwords must match</div>
                            <div *ngIf="hasRegexError()" class="small errortext" [style.position]="'block'" id="failedPatternMessage">
                                {{ config.messageOverrides.failedPattern }}
                            </div>
                            <label>Confirm New Password</label>
                            <div class="form-group has-feedback" [class.has-error]="showConfirmPasswordRequiredError()">
                                <input
                                    #PasswordConfirm
                                    [type]="confirmPasswordType"
                                    autocomplete="off"
                                    class="form-control input"
                                    placeholder="Confirm Password"
                                    formControlName="ConfirmPassword"
                                />
                                <div
                                    *ngIf="confirmPasswordType === 'password'"
                                    class="fa fa-eye-slash form-control-feedback"
                                    (click)="toggleConfirmPasswordType()"
                                ></div>
                                <div
                                    *ngIf="confirmPasswordType === 'text'"
                                    class="fa fa-eye form-control-feedback"
                                    (click)="toggleConfirmPasswordType()"
                                ></div>
                                <div *ngIf="showConfirmPasswordRequiredError()" class="small errortext" [style.position]="'block'">
                                    Secondary Password is required
                                </div>
                            </div>
                            <button type="submit" mtDisableClickDuringHttpCalls class="mt-button">
                                {{ config.messageOverrides.resetPasswordSaveButtonText }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="login-box-body">
                <ng-container *ngIf="passwordResetSuccess">
                    <div class="text-center">
                        <b>Password has been successfully reset!</b>
                        <br/>
                        <br/>
                        <img src="/assets/images/verification.png" alt="Verification logo" class="verification" />
                    </div>
                    <br/>
                    <div  class="text-center">
                        Please return to the mobile app and use your new password.
                        <br />
                        <a *ngIf="isApple || isAndroid" [href]="loginLink">Log In</a>
                        <br />
                        <br />
                        <div class="buttons">
                            <button *ngIf="!isApple" class="google-play social-btn"> </button>
                            <button *ngIf="!isAndroid" class="ios social-btn"></button>
                        </div>
                    </div>
                </ng-container>
            </div>

</div>
