import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from '@mt-ng2/cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';

import { AppRoutingModule } from './app-routing.module';
import { AdminPortalSharedModule } from '@common/shared.module';
import { AppComponent } from './app.component';
import { environment } from '@environment';
import { LoginConfigOverride } from '@common/configs/login.config';
import { ResetPasswordComponent } from 'admin-portal/reset-password/reset-password.component';
import { SignUpSuccessComponent } from 'admin-portal/sign-up-success/sign-up-success.component';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, ResetPasswordComponent, SignUpSuccessComponent],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        NgbModule,
        AdminPortalSharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.withOptions({
            secure: true,
            sameSite: 'strict',
        }),
        EnvironmentModule,
        NotificationsModule,
        AuthModule.forRoot(),
        MtDisableDuringHttpCallsModule.forRoot(),
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {}
