export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    AccessRequests = 7,
    MobileUsers = 8,
    Families = 9,
    Fundamentals = 10,
    Resources = 11,
    AdminNotifications = 12,
}
